import React from 'react'
import SvgIcon, { type SvgIconProps } from '~/components/SvgIcon'

const Check = (props: SvgIconProps) => {
  return (
    <SvgIcon width="16" height="16" viewBox="0 0 16 16" {...props}>
      <path d="M15 4.04688L14.4688 4.57812L6.53125 12.4844L6 13.0156L1 8.01562L2.03125 6.95312L2.5625 7.48438L6 10.8906L13.4062 3.51562L13.9375 2.98438L15 4.04688Z" />
    </SvgIcon>
  )
}

export default Check
